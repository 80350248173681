.image {
  width: 100%;
  border-radius: 6px;
  object-fit: cover;
  height: 150px;
}
.imageBox img {
  display: block;
}
.imageBoxEmpty {
  background: rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  position: relative;
}
.imageBox {
  height: 150px;
}
.wrap {
  cursor: pointer;
  overflow: hidden;
  position: relative;
  &:hover {
    .name {
      color: #3f51b5;
      border-bottom-color: #3f51b5 !important;
    }
    .go {
      transform: translateX(0%);
    }
  }
}
.setting {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
}
.settingMenu {
  border: 1px solid #3f51b5;
  padding: 2px 0 3px 0;
  position: absolute;
  top: 5px;
  left: 5px;
  background-color: #fff;
  font-size: 12px;
  white-space: nowrap;
  border-radius: 4px;
}
.settingBtn {
  cursor: pointer;
  position: relative;
  & svg {
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: 24px;
    color: #3f51b5;

    transform: translate(-50%, -50%);
  }
  background-color: #fff;
  top: 5px;
  left: 5px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 1px solid #3f51b5;
}
.settingItem {
  cursor: pointer;
  color: #3f51b5;
  padding: 5px;
  &:not(:first-child) {
    border-top: 1px solid #3f51b5;
  }

  &:first-child {
  }
}
.go {
  transition: transform 0.3s;
  background-color: #3f51b5;
  color: #fff;
  font-weight: 500;
  border-radius: 5px;
  padding: 4px 8px;
  font-size: 12px;
  position: absolute;
  top: 4px;
  right: 4px;
  transform: translateX(calc(100% + 10px));
}
.category {
  margin-top: 8px;
  display: block;
  font-weight: 700;
  margin-bottom: 5px;
}
.name {
  transition: all 0.3s;
  display: inline;
  border-bottom: 1px solid transparent;
}
.user {
  display: flex;
  align-items: center;

  margin-top: 5px;
}
.avatar {
  object-fit: cover;
  display: block;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 10px;
}
.avatarEmpty {
  position: relative;
  display: block;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 10px;

  background-color: rgba(0, 0, 0, 0.1);
}
.username {
  font-weight: 600;
  font-size: 13px;
}
.bottom {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}
.time {
  opacity: 0.5;
}
.price {
  font-weight: 700;
  font-size: 20px;
}
