.loader {
  position: absolute;
  top: 33%;
  left: 43%;
  transform: translate(-50%, -50%);
  width: 48px;
  height: 48px;
  display: block;
  margin: 15px auto;
  //   position: relative;
  color: #3f51b5;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.loader::after,
.loader::before {
  content: '';
  box-sizing: border-box;
  position: absolute;
  width: 24px;
  height: 24px;
  top: 50%;
  left: 50%;
  transform: scale(0.5) translate(0, 0);
  background-color: #3f51b5;
  border-radius: 50%;
  animation: animloader 1s infinite ease-in-out;
}
.loader::before {
  background-color: #3f51b5;
  transform: scale(0.5) translate(-48px, -48px);
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes animloader {
  50% {
    transform: scale(1) translate(-50%, -50%);
  }
}
