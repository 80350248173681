.wrap {
  display: grid;
  grid-template-columns: 600px 1fr;
  grid-gap: 20px;
  margin-top: 20px;
}
.left {
}
.descTitle {
  margin-top: 40px;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 10px;
}
.desc {
}
.right {
}
.price {
  font-size: 26px;
  font-weight: 700;
}
.title {
  font-size: 22px;
  font-weight: 600;
  margin: 0;
  display: block;
  margin-top: 5px;
  margin-bottom: 10px;
}
.date {
  font-size: 16px;
  opacity: 0.7;
}
.user {
  display: flex;
  align-items: center;
  margin-top: 20px;
}
.tel {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: 600;
  color: #3f51b5;
  border: 1px solid #3f51b5;
  height: 42.25px;
  margin-top: 30px;
}
.avatar {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  object-fit: cover;
  display: block;
  position: relative;
  margin-right: 10px;
}
.userInfo {
  font-size: 14px;
}
.userName {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 5px;
}
.userAdvertCount {
  color: #3f51b5;
  font-weight: 600;
}
