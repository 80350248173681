.left {
}
.wrap {
  margin-top: 30px;
  display: grid;
  grid-template-columns: minmax(150px, auto) 1fr;
  grid-gap: 20px;
}
.statusList {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
}
.statusItem {
  user-select: none;
  cursor: pointer;
  position: relative;
  &Active {
    &::after {
      width: 100% !important;
    }
  }
  &::after {
    content: '';
    display: block;
    position: absolute;
    width: 0;
    min-height: 2.5px;
    bottom: -2px;
    transition: all 0.3s;
  }
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 16px;
  & + & {
    margin-top: 10px;
  }
  &:before {
    content: '';
    display: block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 7px;
  }
}
.statusActive {
  color: #66bb6a;
  &::before,
  &::after {
    background-color: #66bb6a;
  }
}
.statusNoActive {
  color: #f44336;
  &::before,
  &::after {
    background-color: #f44336;
  }
}
.statusCancel {
  color: #ffa726;
  &::before,
  &::after {
    background-color: #ffa726;
  }
}
.statusSold {
  color: #29b6f6;
  &::before,
  &::after {
    background-color: #29b6f6;
  }
}
.right {
  position: relative;
}
.list {
  display: grid;
  grid-gap: 40px 20px;
  grid-template-columns: 1fr 1fr 1fr;
}
